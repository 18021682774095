import { PAYMENT_STATUS } from "../constants";

export const trackPurchase = (order) => {
  if (order.status === PAYMENT_STATUS.TXN_SUCCESS) {
    window.gtag("event", "purchase", {
      transaction_id: order.id,
      affiliation: "DVA Website",
      value: order.total,
      currency: order.currency_symbol,
      items: order.line_items.map((item) => ({
        item_name: item.name,
        item_id: item.id,
        price: item.price,
        quantity: item.quantity,
        total: item.total,
      })),
    });
  }
};

export const trackAddToCart = (product) => {
  window.gtag("event", "add_to_cart_v1", {
    event_category: "ecommerce",
    event_label: product.name,
    value: product.regular_price,
    items: [
      {
        item_name: product.name,
        item_id: product.id,
        price: product.regular_price,
        quantity: 1,
      },
    ],
  });
};

export const trackSearchQuery = (searchTerm) => {
  window.gtag("event", "search", {
    search_term: searchTerm,
  });
};
